module.exports = [{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":620,"quality":100,"withWebp":false,"withAvif":true,"linkImagesToOriginal":false,"backgroundColor":"none","disableBgImage":true,"showCaptions":true,"markdownCaptions":true,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-NMD88S7420"],"gtagConfig":{"anonymize_ip":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Me4502's Website","short_name":"me4502","start_url":"/","background_color":"#6323B2","theme_color":"#6323B2","display":"minimal-ui","icon":"src/images/site-logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b38301b77a804ba8035ab23ab89e7688"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
