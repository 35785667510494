exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-apps-emojify-tsx": () => import("./../../../src/pages/apps/emojify.tsx" /* webpackChunkName: "component---src-pages-apps-emojify-tsx" */),
  "component---src-pages-apps-fractal-explorer-tsx": () => import("./../../../src/pages/apps/fractal-explorer.tsx" /* webpackChunkName: "component---src-pages-apps-fractal-explorer-tsx" */),
  "component---src-pages-apps-resume-generator-tsx": () => import("./../../../src/pages/apps/resume-generator.tsx" /* webpackChunkName: "component---src-pages-apps-resume-generator-tsx" */),
  "component---src-pages-apps-schematic-info-tsx": () => import("./../../../src/pages/apps/schematic-info.tsx" /* webpackChunkName: "component---src-pages-apps-schematic-info-tsx" */),
  "component---src-pages-apps-spongify-tsx": () => import("./../../../src/pages/apps/spongify.tsx" /* webpackChunkName: "component---src-pages-apps-spongify-tsx" */),
  "component---src-pages-archive-internet-speed-tsx": () => import("./../../../src/pages/archive/internet-speed.tsx" /* webpackChunkName: "component---src-pages-archive-internet-speed-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-game-cogworks-index-tsx": () => import("./../../../src/pages/game/cogworks/index.tsx" /* webpackChunkName: "component---src-pages-game-cogworks-index-tsx" */),
  "component---src-pages-game-cogworks-play-tsx": () => import("./../../../src/pages/game/cogworks/play.tsx" /* webpackChunkName: "component---src-pages-game-cogworks-play-tsx" */),
  "component---src-pages-game-cohesion-index-tsx": () => import("./../../../src/pages/game/cohesion/index.tsx" /* webpackChunkName: "component---src-pages-game-cohesion-index-tsx" */),
  "component---src-pages-game-cohesion-play-tsx": () => import("./../../../src/pages/game/cohesion/play.tsx" /* webpackChunkName: "component---src-pages-game-cohesion-play-tsx" */),
  "component---src-pages-game-creditcheque-index-tsx": () => import("./../../../src/pages/game/creditcheque/index.tsx" /* webpackChunkName: "component---src-pages-game-creditcheque-index-tsx" */),
  "component---src-pages-game-creditcheque-play-tsx": () => import("./../../../src/pages/game/creditcheque/play.tsx" /* webpackChunkName: "component---src-pages-game-creditcheque-play-tsx" */),
  "component---src-pages-game-dropdown-index-tsx": () => import("./../../../src/pages/game/dropdown/index.tsx" /* webpackChunkName: "component---src-pages-game-dropdown-index-tsx" */),
  "component---src-pages-game-dropdown-play-tsx": () => import("./../../../src/pages/game/dropdown/play.tsx" /* webpackChunkName: "component---src-pages-game-dropdown-play-tsx" */),
  "component---src-pages-game-portal-2-d-index-tsx": () => import("./../../../src/pages/game/portal2d/index.tsx" /* webpackChunkName: "component---src-pages-game-portal-2-d-index-tsx" */),
  "component---src-pages-game-portal-2-d-play-tsx": () => import("./../../../src/pages/game/portal2d/play.tsx" /* webpackChunkName: "component---src-pages-game-portal-2-d-play-tsx" */),
  "component---src-pages-game-spacebeetle-index-tsx": () => import("./../../../src/pages/game/spacebeetle/index.tsx" /* webpackChunkName: "component---src-pages-game-spacebeetle-index-tsx" */),
  "component---src-pages-game-spacebeetle-privacy-tsx": () => import("./../../../src/pages/game/spacebeetle/privacy.tsx" /* webpackChunkName: "component---src-pages-game-spacebeetle-privacy-tsx" */),
  "component---src-pages-game-tidyhomebound-index-tsx": () => import("./../../../src/pages/game/tidyhomebound/index.tsx" /* webpackChunkName: "component---src-pages-game-tidyhomebound-index-tsx" */),
  "component---src-pages-game-tidyhomebound-play-tsx": () => import("./../../../src/pages/game/tidyhomebound/play.tsx" /* webpackChunkName: "component---src-pages-game-tidyhomebound-play-tsx" */),
  "component---src-pages-game-triage-index-tsx": () => import("./../../../src/pages/game/triage/index.tsx" /* webpackChunkName: "component---src-pages-game-triage-index-tsx" */),
  "component---src-pages-game-triage-play-tsx": () => import("./../../../src/pages/game/triage/play.tsx" /* webpackChunkName: "component---src-pages-game-triage-play-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-minecraft-tsx": () => import("./../../../src/pages/minecraft.tsx" /* webpackChunkName: "component---src-pages-minecraft-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-templates-blog-index-tsx": () => import("./../../../src/templates/BlogIndex.tsx" /* webpackChunkName: "component---src-templates-blog-index-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/BlogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-talk-index-tsx": () => import("./../../../src/templates/TalkIndex.tsx" /* webpackChunkName: "component---src-templates-talk-index-tsx" */),
  "component---src-templates-talk-page-tsx": () => import("./../../../src/templates/TalkPage.tsx" /* webpackChunkName: "component---src-templates-talk-page-tsx" */),
  "slice---src-slices-header-slice-tsx": () => import("./../../../src/slices/HeaderSlice.tsx" /* webpackChunkName: "slice---src-slices-header-slice-tsx" */)
}

